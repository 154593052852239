import React, { useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { useForm } from "@mantine/form";
import {
  Breadcrumbs,
  Button,
  Flex,
  Grid,
  Image,
  Loader,
  Switch,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";

//import ModelDropzone from "./dropzone";
import LicenseTypeSelect from "../form/license_type_select";
import { useGetModel, useUpdateModel } from "../../hooks/model";
import { useGetCurrentUser } from "../../hooks/users";
import { MantineLink } from "../mantine";

const ModelEdit = () => {
  const { uuid } = useParams();
  const { data: user, isPending: isPendingUser } = useGetCurrentUser();
  const { data: model, isPending: isPendingModel } = useGetModel({
    uuid,
  });
  const { mutate: modelMutate, isPending: isPendingModelUpdate } =
    useUpdateModel({
      onValidationError: (error) => {
        form.setErrors(error);
      },
    });

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      name: "",
      summary: "",
      is_draft: "",
      license: "",
    },
  });

  useEffect(() => {
    if (model) {
      form.setValues(model);
    }
  }, [model]);

  const handleSubmit = (values) => {
    modelMutate(
      {
        uuid: model.uuid,
        ...values,
      },
      {
        onSuccess: () => {
          notifications.show({
            title: "Model updated",
            message: "Model has been updated successfully",
            color: "blue",
          });
        },
      },
    );
  };

  if (isPendingModel || isPendingUser) {
    return <Loader />;
  }

  if (!model) {
    return <div>Model not found</div>;
  }
  if (model.owner.uuid !== user?.uuid && !user?.is_superuser) {
    return <div>Not authorized</div>;
  }

  return (
    <>
      <Breadcrumbs separatorMargin="md" mb="md">
        <MantineLink to="/">Home</MantineLink>
        <MantineLink to={`/model/u/${model.uuid}`}>Back to model</MantineLink>
        <Text>Edit model</Text>
      </Breadcrumbs>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Image
          src={new URL(model.image_url, import.meta.env.VITE_BASE_URL)}
          h={120}
          w="auto"
          fit="contain"
          fallbackSrc={
            new URL(
              "/placeholder?width=480&height=480",
              import.meta.env.VITE_BASE_URL,
            )
          }
        />
        <Grid align="stretch">
          <Grid.Col span={8}>
            <TextInput
              label="Name"
              required={!form.getValues().is_draft}
              key={form.key("name")}
              placeholder="The name of the model"
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Flex align="center" justify="flex-end">
              <Switch
                label={form.getValues().is_draft ? "Draft" : "Public"}
                checked={!form.getValues().is_draft}
                onChange={(event) =>
                  form.setFieldValue("is_draft", !event.currentTarget.checked)
                }
              />
              <Button
                type="button"
                variant="outline"
                component={Link}
                color="red"
                to={`/model/u/${model.uuid}`}
                m="md"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="outline"
                color="blue"
                loading={isPendingModelUpdate}
              >
                Save
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
        <Textarea
          label="Summary"
          required={!form.getValues().is_draft}
          autosize
          minRows={5}
          maxRows={15}
          resize="vertical"
          key={form.key("summary")}
          placeholder="Describe the model here"
          {...form.getInputProps("summary")}
        />
        <LicenseTypeSelect
          key={form.key("license")}
          required={!form.getValues().is_draft}
          {...form.getInputProps("license")}
        />
      </form>
    </>
  );
};

export default ModelEdit;
