import React from "react";
import { Text } from "@mantine/core";
import { Container } from "@mantine/core";

import Layout from "./Layout";

function About() {
  return (
    <Layout>
      <Container>
        <h1>About PrintMyModel</h1>
        <Text>
          Printmymodel was inspired by a love for 3D printing and the desire to
          turn a creative hobby into something more. It began with the simple
          idea of making it easier for people to share and sell their 3D-printed
          creations. What started as a small side project has grown into a
          platform dedicated to empowering creators and bringing their
          3D-printed visions to life.
        </Text>
        <h2>What We Do</h2>
        <Text>
          At Printmymodel, we believe in the power of creativity and the
          potential of 3D printing to transform ideas into tangible objects. Our
          platform is a marketplace where anyone can upload their 3D models and
          sell the physical versions to a community of buyers. Unlike other
          platforms, we don’t deal in digital assets—we focus solely on the
          physical, ensuring that each model you purchase has been carefully
          crafted by a skilled creator.
        </Text>

        <h2>How It Works</h2>

        <Text>
          Our platform is simple and straightforward. If you&apos;ve designed a
          3D model that you&apos;re legally allowed to sell, you can upload it
          to Printmymodel and list it for sale. You have the option to either
          print and ship the model yourself or allow others to do it for you. If
          you choose the latter, you&apos;ll earn a commission on each sale,
          giving you the freedom to focus on what you do best—creating. Whether
          you&apos;re a seasoned 3D printing professional or just getting
          started, Printmymodel offers an easy and effective way to monetize
          your passion and connect with a community of like-minded creators.
        </Text>
        <div style={{ height: "50vh" }}></div>
      </Container>
    </Layout>
  );
}

export default About;
