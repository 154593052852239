import React from "react";
import { Container, Group, NumberFormatter, Table, Text } from "@mantine/core";

import Layout from "./Layout";
import { useGetCart } from "../hooks/cart";

function Cart() {
  const { data: cart, isError, isPending } = useGetCart();
  return (
    <Layout>
      <Container>
        <h1>Cart</h1>
        {isPending && <div>Loading...</div>}
        {isError && <div>Error loading cart</div>}
        {cart && (
          <>
            <h2>Items</h2>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Model</Table.Th>
                  <Table.Th>Individual Part</Table.Th>
                  <Table.Th>Price</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {cart.cart_items.map((cart_item) => (
                  <Table.Tr key={cart_item.uuid}>
                    <Table.Td>{cart_item.model.name}</Table.Td>
                    <Table.Td>
                      {!cart_item.model_file ? (
                        <i>Complete model</i>
                      ) : (
                        cart_item.model_file.file_name
                      )}
                    </Table.Td>
                    <Table.Td>{cart_item.price}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>

            <Group justify="flex-end" mt="md">
              <Text weight={500} size="lg">
                Total:{" "}
                <NumberFormatter
                  prefix="$"
                  value={cart.total_price}
                  thousandSeparator
                />
              </Text>
            </Group>
          </>
        )}
        <div style={{ height: "50vh" }}></div>
      </Container>
    </Layout>
  );
}

export default Cart;
