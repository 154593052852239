import axios from "./axios";

export const loginUser = async ({ username, password }) => {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  return axios.post("/auth/jwt/login", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

};
