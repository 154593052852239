import { useMutation } from "@tanstack/react-query";

import { loginUser } from "../api/login";
import axios from "../api/axios";

export const useLogin = () => {
  return useMutation({
    mutationFn: async ({ username, password }) => {
      return loginUser({ username, password });
    }
  });
};

export const useLogout = () => {
  return useMutation({
    mutationFn: async () => {
      return axios.post("/auth/jwt/logout");
    }
  });
};
