import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Card,
  Flex,
  Image,
  NumberFormatter,
  Text,
  Grid,
  Group,
} from "@mantine/core";
import { Loader } from "@mantine/core";

import { useGetFrontPageModels } from "../../hooks/model";

const FrontPageModelItem = (model) => {
  const navigate = useNavigate();
  const handleClick = (uuid) => {
    navigate(`/model/u/${uuid}`);
  };

  return (
    <Card
      shadow="sm"
      padding="md"
      radius="md"
      withBorder
      style={{ cursor: "pointer" }}
      onClick={() => handleClick(model.uuid)}
    >
      <Card.Section>
        <Image
          src={new URL(model.image_url, import.meta.env.VITE_BASE_URL)}
          h={220}
        />
      </Card.Section>
      <Flex justify="space-between" mt="md" mb="xs">
        <Text size="sm" truncate="end" title={model.name}>
          {model.name}
        </Text>
        {model.price && (
          <Badge color="pink" variant="filled" style={{ minWidth: 60 }}>
            <NumberFormatter
              prefix="$"
              value={model.price}
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Badge>
        )}
      </Flex>
    </Card>
  );
};

const FrontPageModelList = () => {
  const { data, isPending } = useGetFrontPageModels();

  if (isPending) {
    return <Loader />;
  }

  if (!data) {
    return <div>No models found</div>;
  }

  return (
    <Grid>
      {data.map((model) => (
        <Grid.Col span={{ sm: 12, md: 6, lg: 4 }} key={model.uuid}>
          <FrontPageModelItem {...model} />
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default FrontPageModelList;
