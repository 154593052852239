import React from "react";
import { Container } from "@mantine/core";

import Layout from "./Layout";
import FrontPageModelList from "./model/frontpage";
import Hero from "./home/hero";

function Home() {
  return (
    <Layout>
      <Container fluid>
        <Hero />
      </Container>
      <Container>
        <FrontPageModelList />
      </Container>
    </Layout>
  );
}

export default Home;
