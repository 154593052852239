import React from "react";

import { useForm } from "@mantine/form";
import { Button, TextInput } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { Link, useParams } from "react-router-dom";

import Layout from "./Layout";
import { useResetPassword } from "../hooks/reset_password";

function ResetPassword() {
  const form = useForm({
    initialValues: {
      password: "",
    },
  });

  const resetPasswordMutation = useResetPassword();

  const { token } = useParams();

  const handleSubmit = (values) => {
    resetPasswordMutation.mutate(
      { token, password: values.password },
      {
        onSuccess: () => {
          notifications.show({
            title: "Password reset",
            message:
              "Password reset successfully. Please login using your new password.",
            color: "blue",
          });
        },
      },
    );
  };

  return (
    <Layout>
      <div className="container">
        <h2>Reset Password</h2>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Password"
            type="password"
            key={form.key("password")}
            {...form.getInputProps("password")}
          />
          {resetPasswordMutation.isError && (
            <div className="alert alert-danger" role="alert">
              Reset password failed: {resetPasswordMutation.error.message}
            </div>
          )}
          {resetPasswordMutation.isSuccess && (
            <div className="alert alert-success" role="alert">
              Password reset successfully. <Link to="/login">Login</Link>
            </div>
          )}
          <Button type="submit" disabled={resetPasswordMutation.isPending}>
            Reset Password
          </Button>
        </form>
      </div>
    </Layout>
  );
}

export default ResetPassword;
