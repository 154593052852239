import { useQuery } from "@tanstack/react-query";
import axios from "../api/axios";

export const useGetLicenseTypes = () => {
  return useQuery({
    queryKey: ['license_types'],

    queryFn: async () => {
      return await axios.get("/license-type/all").then((res) => res.data.data);
    }
  });
};
