import React from "react";
import { Button, Checkbox, Group, Loader, TextInput } from "@mantine/core";

import LayoutSettings from "./LayoutSettings";
import { useGetCurrentUser } from "../hooks/users";

function Webhooks() {
  const {
    data: user,
    isErrorUser,
    isPending: isPendingUser,
  } = useGetCurrentUser();

  if (isPendingUser) {
    return <Loader />;
  }
  if (!user || isErrorUser) {
    return <></>;
  }

  return (
    <LayoutSettings activeTab="webhooks">
      <h3>Add a Webhook</h3>
      <Group position="center">
        <Checkbox label="New Order" />
        <Checkbox label="Order Shipped" />
      </Group>
      <TextInput
        label="Webhook URL"
        placeholder="https://print.my.site/send-to-printer"
        description="This is the URL that will receive the webhook payload"
      />
      <Button style={{ marginTop: 10 }} variant="light">
        Save
      </Button>
    </LayoutSettings>
  );
}

export default Webhooks;
