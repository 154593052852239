import { useMutation } from "@tanstack/react-query";
import axios from "../api/axios";

export const useRegisterUser = () => {
  return useMutation({
    mutationFn: async ({ username, email, password, timezone }) => {
      return axios.post("/auth/register", {
        username,
        email,
        password,
        timezone,
      });
    }
  });
};
