import React from "react";
import { Container } from "@mantine/core";
import Markdown from "react-markdown";

import privacy from "../../markdown/privacy.md?raw";
import Layout from "./Layout";

function Privacy() {
  return (
    <Layout>
      <Container>
        <Markdown>{privacy}</Markdown>
        <div style={{ height: "50vh" }}></div>
      </Container>
    </Layout>
  );
}

export default Privacy;
