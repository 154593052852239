import React from "react";
import { Link } from "react-router-dom";

import { ActionIcon, Button, Group, Loader } from "@mantine/core";
import { IconSettings, IconUpload, IconTag } from "@tabler/icons-react";

import CartWidget from "./cart/widget";
import { useGetCurrentUser } from "../hooks/users";

function Navbar() {
  const { data: user, isError, isPending } = useGetCurrentUser();

  if (isPending) {
    return <Loader />;
  }

  if (isError) {
    return <></>;
  }
  return (
    <Group>
      {user && (
        <>
          <Button
            to="/model/new"
            component={Link}
            leftSection={<IconUpload size={14} />}
            variant="default"
          >
            Upload a Model
          </Button>
          <Button
            to="/purchases"
            component={Link}
            leftSection={<IconTag size={14} />}
            variant="default"
          >
            Sell
          </Button>
        </>
      )}
      <CartWidget />
      {user && (
        <ActionIcon
          component={Link}
          to="/orders"
          variant="transparent"
          aria-label="Settings"
          mr="md"
        >
          <IconSettings />
        </ActionIcon>
      )}
    </Group>
  );
}

export default Navbar;
