import { useMutation } from "@tanstack/react-query";
import axios from "../api/axios";

export const useResetPassword = () => {
  return useMutation({
    mutationFn: async ({ token, password }) => {
      return axios.post("/auth/reset-password", { token, password });
    }
  });
};
