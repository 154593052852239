import React from "react";
import { Navigate } from "react-router-dom";
import { Loader } from "@mantine/core";
import { useGetCurrentUser } from "../hooks/users";

const ProtectedRoute = ({ element }) => {
  const { data: user, isPending, isError } = useGetCurrentUser();

  if (isPending) {
    return <Loader />;
  }

  if (isError || !user) {
    return <Navigate to="/login" replace />;
  }

  return element;
};

export default ProtectedRoute;
