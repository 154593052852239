import React from "react";
import { Container } from "@mantine/core";
import Markdown from "react-markdown";

import privacyPractices from "../../markdown/privacy_practices.md?raw";
import Layout from "./Layout";

function PrivacyPractices() {
  return (
    <Layout>
      <Container>
        <Markdown>{privacyPractices}</Markdown>
        <div style={{ height: "50vh" }}></div>
      </Container>
    </Layout>
  );
}

export default PrivacyPractices;
