import React from "react";
import { Loader } from "@mantine/core";

import LayoutSettings from "./LayoutSettings";
import { useGetCurrentUser } from "../hooks/users";

function Settings() {
  const {
    data: user,
    isErrorUser,
    isPending: isPendingUser,
  } = useGetCurrentUser();

  if (isPendingUser) {
    return <Loader />;
  }
  if (!user || isErrorUser) {
    return <></>;
  }

  return <LayoutSettings activeTab="settings"></LayoutSettings>;
}

export default Settings;
