import React from "react";
import PropTypes from "prop-types";
import {
  ActionIcon,
  Badge,
  Breadcrumbs,
  Burger,
  Button,
  Card,
  Grid,
  Group,
  Image,
  Menu,
  Select,
  Tabs,
  Text,
  rem,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import {
  IconBook2,
  IconCreditCard,
  IconCube,
  IconDownload,
  IconLicense,
  IconShoppingCart,
} from "@tabler/icons-react";
import { Link, useParams } from "react-router-dom";
import { Table, Loader } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";

import { MantineLink } from "../mantine";
import { useGetModel } from "../../hooks/model";
import { useGetCurrentUser } from "../../hooks/users";
import { useAddToCart } from "../../hooks/cart";
import classes from "./carousel.module.css";
import prettyBytes from "pretty-bytes";

function AmazonMoneyDisplay({ amount, currency = "$" }) {
  const [integerPart, decimalPart] = amount.split(".");

  return (
    <>
      <span
        style={{
          fontSize: "0.6em",
          top: "-.75em",
          position: "relative",
        }}
      >
        {currency}
      </span>
      <span
        style={{
          fontSize: "1.25em",
        }}
      >
        {integerPart}
      </span>
      <span
        style={{
          fontSize: "0.6em",
          top: "-.75em",
          position: "relative",
        }}
      >
        {decimalPart && decimalPart.length > 2
          ? decimalPart.slice(0, 2)
          : decimalPart}
      </span>
    </>
  );
}

AmazonMoneyDisplay.propTypes = {
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string,
};

const ModelItem = () => {
  const { uuid } = useParams();
  const { data: model, isPending: isPendingModel } = useGetModel({ uuid });
  const { data: user, isPending: isPendingUser } = useGetCurrentUser();
  const { mutate: addToCartMutation, isPending: isPendingAddToCart } =
    useAddToCart();

  const navigate = useNavigate();

  if (isPendingModel || isPendingUser) {
    return <Loader />;
  }

  const handleAddToCart = ({ model, model_file }) => {
    addToCartMutation(
      { model_uuid: model.uuid, model_file_uuid: model_file?.uuid },
      {
        onSuccess: () => {
          notifications.show({
            title: "Added to cart",
            message: "Model added to cart",
            color: "green",
          });
        },
      },
    );
  };

  if (!model) {
    return <div>Model not found</div>;
  }
  const isOwner = model.owner.id === user?.id;

  return (
    <>
      <Breadcrumbs separatorMargin="md" mb="md">
        <MantineLink to="/">Home</MantineLink>
        {model.name && model.name.length > 0 ? (
          <Text>{model.name}</Text>
        ) : (
          <Text c="dimmed" fs="italic">
            Untitled
          </Text>
        )}
      </Breadcrumbs>
      {isOwner && model.is_draft && (
        <Text color="red" style={{ marginBottom: rem(16) }}>
          This model is a draft. It will not be visible to others until you
          publish it.{" "}
          <Link to={`/model/u/${model.uuid}/edit`}>
            Click here to finish it
          </Link>
        </Text>
      )}
      <Group justify="space-between" style={{ flex: 1 }}>
        <h1>
          {model.name && model.name.length > 0 ? (
            model.name
          ) : (
            <MantineLink
              to={`/model/u/${model.uuid}/edit`}
              title="Click to edit"
            >
              <Text c="dimmed">Untitled</Text>
            </MantineLink>
          )}
        </h1>
        <Group ml="xl" gap={0} visibleFrom="sm">
          {isOwner && (
            <Menu>
              <Menu.Target>
                <Burger aria-label="Open menu" />
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item>
                  <MantineLink to={`/model/u/${model.uuid}/edit`}>
                    Edit
                  </MantineLink>
                </Menu.Item>
                <Menu.Item>Delete</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </Group>
      </Group>
      <Carousel
        withIndicators
        height={480}
        slideGap="sm"
        slideSize="80%"
        draggable={false}
        classNames={classes}
      >
        {model.model_files.map((model_file) => (
          <Carousel.Slide key={model_file.uuid}>
            <Image
              src={new URL(model_file.image_url, import.meta.env.VITE_BASE_URL)}
              h={480}
              w="auto"
              fit="contain"
              fallbackSrc={
                new URL(
                  "/placeholder?width=480&height=480",
                  import.meta.env.VITE_BASE_URL,
                )
              }
            />
          </Carousel.Slide>
        ))}
      </Carousel>
      <Tabs defaultValue="overview" style={{ marginTop: rem(16) }}>
        <Tabs.List>
          <Tabs.Tab value="overview" leftSection={<IconBook2 />}>
            Overview
          </Tabs.Tab>
          <Tabs.Tab value="download" leftSection={<IconCube />}>
            Download
          </Tabs.Tab>
          <Tabs.Tab value="order" leftSection={<IconCreditCard />}>
            Shop Individual Parts
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="overview">
          <Grid>
            <Grid.Col span={8}>
              <h2>About this item</h2>
              {model.summary && model.summary.length > 0 ? (
                <Text style={{ whiteSpace: "pre-wrap", minHeight: rem(200) }}>
                  {model.summary}
                </Text>
              ) : (
                <MantineLink
                  to={`/model/u/${model.uuid}/edit`}
                  title="Click to edit"
                >
                  <Text c="dimmed">no summary... click here to add one</Text>
                </MantineLink>
              )}
            </Grid.Col>
            <Grid.Col span={4}>
              <Card withBorder shadow="sm" radius="md" mt="sm">
                <Card.Section withBorder inheritPadding py="xs">
                  <Text fw={700} size="xl">
                    {model.price ? (
                      <AmazonMoneyDisplay amount={model.price} />
                    ) : (
                      "Price not available"
                    )}
                  </Text>
                  <Select
                    label="Quantity"
                    disabled={!model.price}
                    defaultValue="1"
                    size="xs"
                    data={["1", "2", "3", "4", "5"]}
                  />
                  <Group m="sm">
                    <Button
                      color="orange"
                      onClick={() => handleAddToCart({ model })}
                      disabled={isPendingAddToCart || !model.price}
                      leftSection={<IconShoppingCart />}
                    >
                      Add to Cart
                    </Button>
                  </Group>
                </Card.Section>
              </Card>

              {/*
              <Card withBorder shadow="sm" radius="md" mt="sm">
                <Card.Section withBorder inheritPadding py="xs">
                  <Text fw={700} size="sm" align="center">
                    Other sellers on PrintMyModel
                  </Text>
                </Card.Section>
                <Group m="sm">
                  <Text fw={700} size="xl">
                    <AmazonMoneyDisplay amount="9.00" />
                  </Text>
                  <Text component="span" size="sm">
                    Order from <a href="about:blank">bobsprintshop</a>
                    <Space />
                    <Text component="span" size="xs" fs="italic">
                      Free shipping orders over $25
                    </Text>
                  </Text>
                  <Group justify="right">
                    <Button color="blue" size="xs">
                      Order from bobsprintshop
                    </Button>
                  </Group>
                </Group>
              </Card>
              */}
            </Grid.Col>
          </Grid>
        </Tabs.Panel>
        <Tabs.Panel value="download">
          <Table mt="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th>File Name</Table.Th>
                <Table.Th>Size</Table.Th>
                <Table.Th>Download</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {model.model_files.map((model_file) => (
                <Table.Tr key={model_file.uuid}>
                  <Table.Td>
                    <Image
                      src={
                        new URL(
                          model_file.image_url,
                          import.meta.env.VITE_BASE_URL,
                        )
                      }
                      alt={model_file.file_name}
                      h={80}
                      w="auto"
                      fit="contain"
                    />
                  </Table.Td>
                  <Table.Td>{model_file.file_name}</Table.Td>
                  <Table.Td>
                    {prettyBytes(model_file.cloud_object.file_size)}
                  </Table.Td>
                  <Table.Td style={{ textAlign: "center" }}>
                    <ActionIcon variant="transparent">
                      <IconDownload />
                    </ActionIcon>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Tabs.Panel>
        <Tabs.Panel value="order">
          <Text>Order</Text>
          <Table mt="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th>File Name</Table.Th>
                <Table.Th>Size</Table.Th>
                <Table.Th style={{ textAlign: "center" }}></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {model.model_files.map((model_file) => (
                <Table.Tr key={model_file.uuid}>
                  <Table.Td>
                    <Image
                      src={
                        new URL(
                          model_file.image_url,
                          import.meta.env.VITE_BASE_URL,
                        )
                      }
                      alt={model_file.file_name}
                      h={80}
                      w="auto"
                      fit="contain"
                    />
                  </Table.Td>
                  <Table.Td>{model_file.file_name}</Table.Td>
                  <Table.Td>
                    {prettyBytes(model_file.cloud_object.file_size)}
                  </Table.Td>
                  <Table.Td style={{ textAlign: "center" }}>
                    <Button
                      color="orange"
                      onClick={() => handleAddToCart({ model, model_file })}
                      disabled={isPendingAddToCart || !model.price}
                      leftSection={<IconShoppingCart />}
                    >
                      Add to Cart
                    </Button>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Tabs.Panel>
      </Tabs>
      <Badge
        color="gray"
        autoContrast
        leftSection={
          <IconLicense style={{ width: rem(12), height: rem(12) }} />
        }
      >
        {model.license}
      </Badge>
    </>
  );
};

export default ModelItem;
