import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "../api/axios";

export const useCreateModel = () => {
  return useMutation({
    mutationFn: async ({ name, summary, is_draft, license, files }) => {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("summary", summary);
      formData.append("is_draft", is_draft);
      formData.append("license", license);
      files.forEach((file) => {
        formData.append("files", file);
      });

      const response = await axios.post("/model/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response;
    },
  });
};

export const useGetPaginatedModels = () => {
  return useQuery({
    queryKey: ["modelsPaginated"],

    queryFn: async () => {
      return await axios.get("/model").then((res) => res.data.items);
    },
  });
};

export const useGetMyPaginatedModels = () => {
  return useQuery({
    queryKey: ["myModelsPaginated"],

    queryFn: async () => {
      return await axios.get("/model/me").then((res) => res.data.items);
    },
  });
};

export const useGetFrontPageModels = () => {
  return useQuery({
    queryKey: ["frontPageModels"],

    queryFn: async () => {
      return await axios.get("/model/front-page").then((res) => res.data.items);
    },
  });
};

export const useGetModel = ({ uuid }) => {
  return useQuery({
    queryKey: ["model", uuid],

    queryFn: async () => {
      return await axios.get(`/model/uuid/${uuid}`).then((res) => res.data);
    },
    enabled: !!uuid,
  });
};

export const useUpdateModel = ({
  onValidationError = () => {},
  onSuccess = () => {},
  onError = () => {},
} = {}) => {
  return useMutation({
    mutationFn: async ({ uuid, ...values }) => {
      const response = await axios.put(`/model/uuid/${uuid}`, values);
      return response.data;
    },
    onError: (error, variables, context) => {
      if (error.response?.data?.detail) {
        const validationErrors = error.response.data.detail.reduce(
          (acc, err) => {
            const field = err.loc[0];
            acc[field] = err.msg;
            return acc;
          },
          {},
        );

        // Call the onValidationError callback if provided
        onValidationError(validationErrors);
      }

      // Call the onError callback if provided
      onError(error, variables, context);
    },
    onSuccess, // Call the onSuccess callback if provided
  });
};

export const useUploadModelFile = () => {
  return useMutation({
    mutationFn: async ({ uuid, files }) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });

      const response = await axios.post(`/model/uuid/${uuid}/file`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response;
    },
  });
};
