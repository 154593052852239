import React from "react";
import { Container } from "@mantine/core";
import { useParams } from "react-router-dom";

import Layout from "./Layout";
import ModelEdit from "./model/edit";
import ModelItem from "./model/model";

function Model({ edit = false }) {
  return (
    <Layout>
      <Container>
          {edit ? <ModelEdit /> : <ModelItem />}
      </Container>
    </Layout>
  );
}

export default Model;
