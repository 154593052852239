import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Tabs } from "@mantine/core";

import Layout from "./Layout";

function SettingsLayout({ children, activeTab }) {
  const navigate = useNavigate();
  const url_map = {
    orders: "/orders",
    webhooks: "/webhooks",
  };

  return (
    <>
      <Layout>
        <Container>
          <Tabs
            defaultValue={activeTab}
            onChange={(value) => navigate(url_map[value])}
          >
            <Tabs.List>
              <Tabs.Tab value="orders">Orders</Tabs.Tab>
              <Tabs.Tab value="webhooks">Webhooks</Tabs.Tab>
            </Tabs.List>
          </Tabs>
          {children}
        </Container>
      </Layout>
    </>
  );
}

SettingsLayout.propTypes = {
  children: PropTypes.node,
  activeTab: PropTypes.string,
};

export default SettingsLayout;
