import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Group, TextInput } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useForm } from "@mantine/form";

import { useLogin } from "../hooks/login";
import Layout from "./Layout";

function Login() {
  const navigate = useNavigate();
  const loginMutation = useLogin();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      email: "",
      username: "",
      password: "",
    },
  });

  const handleSubmit = () => {
    loginMutation.mutate(form.getValues(), {
      onSuccess: () => {
        navigate("/");
      },
      onError: (error) => {
        notifications.showNotification({
          title: "Login failed",
          message: error.message,
          color: "red",
        });
      },
    });
  };

  return (
    <Layout>
      <Container size="xs">
        <h2>Login</h2>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Email"
            key={form.key("username")}
            {...form.getInputProps("username")}
          />
          <TextInput
            label="Password"
            type="password"
            key={form.key("password")}
            {...form.getInputProps("password")}
          />
          {loginMutation.isError && (
            <div className="alert alert-danger" role="alert">
              Login failed: {loginMutation.error.message}
            </div>
          )}
          <Group justify="flex-end" mt="md">
            <Button type="submit" loading={loginMutation.isPending}>
              Login
            </Button>
          </Group>
        </form>
        <p>
          Don&apos;t have an account? <Link to="/register">Create one</Link>
        </p>
        <p>
          Forgot your password? <Link to="/forgot-password">Reset it</Link>
        </p>
      </Container>
    </Layout>
  );
}

export default Login;
