import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Group, Loader, Menu, Text, UnstyledButton } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";

import { useGetCurrentUser } from "../hooks/users";
import { useLogout } from "../hooks/login";
import { MantineLink } from "./mantine";

function LoginWidget() {
  const { data: user, error, isError, isPending } = useGetCurrentUser();
  const logoutMutation = useLogout();
  const queryClient = useQueryClient();

  const handleLogout = async () => {
    try {
      await logoutMutation.mutateAsync();
      queryClient.invalidateQueries({
        queryKey: ["currentUser"],
      });
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  if (isPending) {
    return <Loader />;
  }
  if (isError) {
    return <p>Error: {error.message}</p>;
  }

  if (!user) {
    return (
      <Group>
        <MantineLink className="nav-link" to="/register" p="md">
          Create an Account
        </MantineLink>
        <MantineLink className="nav-link" to="/login" p="md">
          Login
        </MantineLink>
      </Group>
    );
  }

  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <UnstyledButton>
          <Group>
            <Text>{user.username}</Text>
            <IconChevronDown size="1rem" />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item>
          <MantineLink to="/profile" className="dropdown-item">
            Profile
          </MantineLink>
        </Menu.Item>
        <Menu.Item>
          <Text onClick={handleLogout}>
            {logoutMutation.isPending ? "Logging out..." : "Logout"}
          </Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export default LoginWidget;
