import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Loader, NumberFormatter } from "@mantine/core";
import { IconShoppingCart } from "@tabler/icons-react";

import { useGetCart } from "../../hooks/cart";

function CartWidget() {
  const { data: cart, isError, isPending } = useGetCart();
  if (isPending) {
    return <Loader />;
  }
  if (!cart || isError) {
    return <></>;
  }
  return (
    <Button
      variant="default"
      component={Link}
      to="/cart"
      leftSection={
        <Badge color={cart.total_price > 0 ? "red" : "gray"} variant="filled">
          <NumberFormatter
            prefix="$"
            value={cart.total_price}
            thousandSeparator
          />
        </Badge>
      }
    >
      <IconShoppingCart size={32} />
    </Button>
  );
}

export default CartWidget;
