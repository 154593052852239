import React from "react";
import { useMantineTheme, Group, Text, rem } from "@mantine/core";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";

// Define the allowed extensions and MIME types for both models and supporting files
const MODEL_EXTENSIONS = [
  ".3ds",
  ".3mf",
  ".amf",
  ".bgcode",
  ".blend",
  ".ctb",
  ".dwg",
  ".dxf",
  ".f3d",
  ".f3z",
  ".factory",
  ".fcstd",
  ".gcode",
  ".iges",
  ".ipt",
  ".lys",
  ".lyt",
  ".obj",
  ".ply",
  ".rsdoc",
  ".scad",
  ".shape",
  ".shapr",
  ".skp",
  ".sl1",
  ".sl1s",
  ".sldasm",
  ".sldprt",
  ".slvs",
  ".step",
  ".stl",
  ".stp",
  ".studio3",
  ".zpr",
];

const MODEL_MIME_TYPES = [
  "model/stl",
  "application/octet-stream",
  "application/x-openscad",
  "application/step",
  "application/x-ply",
  "application/x-fbx",
  "application/x-gcode",
  "model/vrml",
  "model/mesh",
  "application/x-amf",
  "application/iges",
  "application/vnd.ms-pkiseccat",
  "application/x-blender",
  "application/dxf",
  "application/dwg",
  "application/f3d",
  "application/fcstd",
  // Add more MIME types as needed
];

const SUPPORTING_EXTENSIONS = [
  ".ai",
  ".cdr",
  ".csv",
  ".gif",
  ".ini",
  ".ino",
  ".jpeg",
  ".jpg",
  ".pdf",
  ".png",
  ".py",
  ".svg",
  ".txt",
  ".webp",
];

const SUPPORTING_MIME_TYPES = [
  MIME_TYPES.jpeg,
  "image/png",
  "application/pdf",
  "image/gif",
  "application/illustrator",
  "text/csv",
  "application/ini",
  "application/vnd.coreldraw",
  "text/x-python",
  "image/svg+xml",
  "text/plain",
  "image/webp",
];

function ModelDropzone({ ...props }) {
  const theme = useMantineTheme();

  const handleDrop = (acceptedFiles) => {
    // Handle the accepted files
    console.log("acceptedFiles", acceptedFiles);
  };

  const handleReject = (rejectedFiles) => {
    // Handle rejected files
    console.error("rejectedFiles", rejectedFiles);
  };

  return (
    <Dropzone
      onDrop={handleDrop}
      onReject={handleReject}
      accept={[...MODEL_MIME_TYPES, ...SUPPORTING_MIME_TYPES]}
      maxSize={50 * 1024 * 1024} // 50 MB limit
      styles={{
        root: {
          border: "2px dashed #ccc",
          borderRadius: "10px",
          padding: "20px",
          textAlign: "center",
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[7]
              : theme.colors.gray[0],
        },
      }}
      {...props}
    >
      <Group
        justify="center"
        gap="xl"
        mih={220}
        style={{ pointerEvents: "none" }}
      >
        <Dropzone.Accept>
          <IconUpload
            style={{
              width: rem(52),
              height: rem(52),
              color: "var(--mantine-color-blue-6)",
            }}
            stroke={1.5}
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            style={{
              width: rem(52),
              height: rem(52),
              color: "var(--mantine-color-red-6)",
            }}
            stroke={1.5}
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconPhoto
            style={{
              width: rem(52),
              height: rem(52),
              color: "var(--mantine-color-dimmed)",
            }}
            stroke={1.5}
          />
        </Dropzone.Idle>
        <div>
          <Text align="center">
            Drag files here or click to select files. Supported file types are:
            <br />
            <strong>Models:</strong> {MODEL_EXTENSIONS.join(", ")}
            <br />
            <strong>Other Files:</strong> {SUPPORTING_EXTENSIONS.join(", ")}
          </Text>
        </div>
      </Group>
    </Dropzone>
  );
}

export default ModelDropzone;
