import { useQuery } from "@tanstack/react-query";
import axios from "../api/axios";

export const useGetTimezones = () => {
  return useQuery({
    queryKey: ['timezones'],

    queryFn: async () => {
      return await axios.get("/timezone/all").then((res) => res.data.data);
    }
  });
};
