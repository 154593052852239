import React from "react";

import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Fieldset,
  Group,
  Switch,
  TextInput,
  Textarea,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";

import { useCreateModel } from "../../hooks/model";
import ModelDropzone from "./dropzone";
import LicenseTypeSelect from "../form/license_type_select";

function Create() {
  const createModelMutation = useCreateModel();
  const navigate = useNavigate();

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      name: "",
      summary: "",
      is_draft: true,
      license: "",
      files: [],
    },
  });

  const handleSubmit = (values) => {
    createModelMutation.mutate(
      {
        ...values,
        files: form.getValues().files,
      },
      {
        onSuccess: (data) => {
          notifications.show({
            title: "Model created",
            message: "Model created successfully",
            color: "blue",
          });
          navigate(`/model/u/${data.data.data.uuid}`);
        },
        onError: (error) => {
          notifications.show({
            title: "Model creation failed",
            message: error.message || "Something went wrong",
            color: "red",
          });
        },
      },
    );
  };
  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Group justify="flex-end" mt="md" p="md">
          <Switch
            label={form.getValues().is_draft ? "Draft" : "Public"}
            checked={!form.getValues().is_draft}
            onChange={(event) =>
              form.setFieldValue("is_draft", !event.currentTarget.checked)
            }
          />
          <Button
            type="submit"
            variant="outline"
            color="blue"
            loading={createModelMutation.isPending}
          >
            Save Draft
          </Button>
        </Group>

        <Group p="md">
          <ModelDropzone
            onDrop={(files) => form.setFieldValue("files", files)}
          />
        </Group>

        <Fieldset legend="Model details">
          <Group>
            <TextInput
              required={!form.getValues().is_draft}
              label="Name"
              placeholder="Model name"
              style={{ flex: 1 }}
              key={form.key("name")}
              {...form.getInputProps("name")}
            />
          </Group>
          <Textarea
            required={!form.getValues().is_draft}
            label="Summary"
            placeholder="Model summary"
            key={form.key("summary")}
            {...form.getInputProps("summary", { type: "textarea" })}
          />
          <LicenseTypeSelect
            required={!form.getValues().is_draft}
            key={form.key("license")}
            {...form.getInputProps("license")}
          />
        </Fieldset>
      </form>
    </>
  );
}

export default Create;
