import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  //IconTruck,
  IconBuildingStore,
  IconClipboardList,
} from "@tabler/icons-react";
import { Container, Tabs } from "@mantine/core";

import Layout from "./Layout";

function LayoutSelling({ children, activeTab }) {
  const navigate = useNavigate();
  const url_map = {
    store: "/store",
    purchases: "/purchases",
  };
  return (
    <>
      <Layout>
        <Container>
          <Tabs
            defaultValue={activeTab}
            onChange={(value) => navigate(url_map[value])}
          >
            <Tabs.List>
              <Tabs.Tab value="store" leftSection={<IconBuildingStore />}>
                My Store
              </Tabs.Tab>
              <Tabs.Tab value="purchases" leftSection={<IconClipboardList />}>
                Orders &amp; Shipping
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>

          {children}
        </Container>
      </Layout>
    </>
  );
}

LayoutSelling.propTypes = {
  children: PropTypes.node,
  activeTab: PropTypes.string,
};

export default LayoutSelling;
