import React from "react";
import { Button, Textarea, TextInput, Container } from "@mantine/core";

import LayoutSelling from "./LayoutSelling";

function Store() {
  return (
    <LayoutSelling activeTab="store">
      <h3>My Store</h3>
      <Container size="sm">
        <TextInput
          label="Store Name"
          placeholder="Store name"
          description="This is the name of your store"
        />
        <Textarea
          label="Store Description"
          placeholder="A little about our store"
        />
        <Button style={{ marginTop: 10 }} variant="light">
          Save
        </Button>
      </Container>
    </LayoutSelling>
  );
}

export default Store;
