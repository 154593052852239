import React from "react";

import { useForm } from "@mantine/form";
import { Button, Container, Group, Loader, TextInput } from "@mantine/core";

import Layout from "./Layout";

import { useGetCurrentUser, usePatchCurrentUser } from "../hooks/users";
import TimezoneSelect from "./form/timezone_select";

function Profile() {
  const { data: user, error, isError, isPending } = useGetCurrentUser();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      username: user?.username,
      email: user?.email,
      timezone: user?.timezone,
    },
  });

  const patchUser = usePatchCurrentUser();
  const handleSubmit = () => {
    patchUser.mutate(form.getValues());
  };

  return (
    <Layout>
      <Container size="sm">
        {isPending && <Loader />}
        {isError && <p>Error: {error.message}</p>}
        {user && (
          <>
            <h3>Update Profile</h3>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <Group>
                <TextInput
                  label="Username"
                  placeholder="Username"
                  required
                  value={form.getValues().username}
                  onChange={(event) =>
                    form.setFieldValue("username", event.currentTarget.value)
                  }
                />
              </Group>
              <Group>
                <TextInput
                  label="Email"
                  placeholder="Email"
                  type="email"
                  disabled
                  required
                  value={form.getValues().email}
                  onChange={(event) =>
                    form.setFieldValue("email", event.currentTarget.value)
                  }
                />
              </Group>
              <Group spacing="md">
                <TimezoneSelect
                  required
                  value={form.getValues().timezone}
                  allowDeselect={false}
                  onChange={(value) => {
                    console.log(value);
                    form.setFieldValue("timezone", value);
                  }}
                />
              </Group>
              <Group justify="flex-end" mt="md">
                <Button
                  type="submit"
                  loading={patchUser.isPending}
                  disabled={!form.isTouched() || !form.isValid()}
                >
                  Save Changes
                </Button>
              </Group>
            </form>
          </>
        )}
      </Container>
    </Layout>
  );
}

export default Profile;
