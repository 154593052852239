import { Select } from "@mantine/core";

import { useGetTimezones } from "../../hooks/timezone";

export default function TimezoneSelect({ ...props }) {
  const { data: timezones, error, isError } = useGetTimezones();

  return (
    <Select
      label="Timezone"
      placeholder="Select your timezone"
      data={timezones}
      error={isError ? error.message : null}
      {...props}
    />
  );
}
