import React from "react";
import {
  Container,
  Divider,
  Flex,
  List,
  Space,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import {
  IconCheck,
  IconBuildingStore,
  IconHelp,
  IconPalette,
  IconPrinter,
} from "@tabler/icons-react";

import Layout from "../Layout";

const HowItWorks = () => {
  return (
    <Layout>
      <Container size="md">
        <Title order={1} align="center" my="lg">
          How It Works
        </Title>

        {/* For Customers */}
        <Flex align="center">
          <IconBuildingStore size={32} />{" "}
          <Title order={2} mt="sm" ml="md" mb="sm">
            For Customers
          </Title>
        </Flex>
        <List
          spacing="sm"
          icon={
            <ThemeIcon color="blue" size={24} radius="xl">
              <IconCheck size={16} />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text weight={500}>Sign Up</Text>
            <Text>Create an account using your email or social media.</Text>
          </List.Item>
          <List.Item>
            <Text weight={500}>Explore the Marketplace</Text>
            <Text>
              Browse various categories like art, collectibles, home decor, and
              more.
            </Text>
          </List.Item>
          <List.Item>
            <Text weight={500}>Preview Models</Text>
            <Text>
              View detailed images and 360-degree previews of models before
              purchase.
            </Text>
          </List.Item>
          <List.Item>
            <Text weight={500}>Purchase Physical Models</Text>
            <Text>
              Add to cart, checkout securely, and receive confirmation of your
              order.
            </Text>
          </List.Item>
          <List.Item>
            <Text weight={500}>Receive Your Order</Text>
            <Text>Track your order and get it delivered to your doorstep.</Text>
          </List.Item>
        </List>

        <Divider my="xl" />

        {/* For Designers */}
        <Flex align="center">
          <IconPalette size={32} />{" "}
          <Title order={2} mt="sm" ml="md" mb="sm">
            For Designers
          </Title>
        </Flex>
        <List
          spacing="sm"
          icon={
            <ThemeIcon color="blue" size={24} radius="xl">
              <IconCheck size={16} />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text weight={500}>Upload Your Designs</Text>
            <Text>
              Easily upload your 3D models and provide descriptions and tags.
            </Text>
          </List.Item>
          <List.Item>
            <Text weight={500}>Set Your Price</Text>
            <Text>Determine your price and earn royalties on each sale.</Text>
          </List.Item>
          <List.Item>
            <Text weight={500}>Publish and Sell</Text>
            <Text>
              After review, your product will go live on the marketplace.
            </Text>
          </List.Item>
          <List.Item>
            <Text weight={500}>Track Performance</Text>
            <Text>Monitor sales and manage payouts from your dashboard.</Text>
          </List.Item>
        </List>

        <Divider my="xl" />

        {/* For Printers */}
        <Flex align="center">
          <IconPrinter size={32} />{" "}
          <Title order={2} mt="sm" ml="md" mb="sm">
            For Printers
          </Title>
        </Flex>
        <List
          spacing="sm"
          icon={
            <ThemeIcon color="blue" size={24} radius="xl">
              <IconCheck size={16} />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text weight={500}>Register as a Printer</Text>
            <Text>
              Sign up and verify your account to start receiving orders.
            </Text>
          </List.Item>
          <List.Item>
            <Text weight={500}>Set Up Your Profile</Text>
            <Text>
              Specify your printing capabilities, pricing, and shipping options.
            </Text>
          </List.Item>
          <List.Item>
            <Text weight={500}>Receive Orders</Text>
            <Text>
              Get notified when customers order prints and access design files.
            </Text>
          </List.Item>
          <List.Item>
            <Text weight={500}>Print and Ship</Text>
            <Text>
              Ensure quality, package the model, and provide shipping
              information.
            </Text>
          </List.Item>
          <List.Item>
            <Text weight={500}>Manage Your Business</Text>
            <Text>
              Track earnings, orders, and reviews from your dashboard.
            </Text>
          </List.Item>
        </List>

        <Divider my="xl" />

        {/* Support and Security */}
        <Flex align="center">
          <IconHelp size={32} />{" "}
          <Title order={2} mt="sm" ml="md" mb="sm">
            Support and Security
          </Title>
        </Flex>
        <List
          spacing="sm"
          icon={
            <ThemeIcon color="blue" size={24} radius="xl">
              <IconCheck size={16} />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text weight={500}>Customer Support</Text>
            <Text>Access our Help Center or contact us for any inquiries.</Text>
          </List.Item>
          <List.Item>
            <Text weight={500}>Secure Transactions</Text>
            <Text>
              We use encrypted payment systems and data protection for secure
              transactions.
            </Text>
          </List.Item>
        </List>

        <Space h="xl" />
      </Container>
    </Layout>
  );
};

export default HowItWorks;
