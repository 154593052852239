import { useMutation } from "@tanstack/react-query";
import axios from "../api/axios";

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: async ({ email }) => {
      return axios.post("/auth/forgot-password", { email });
    }
  });
};
