import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../components/Home";
import Login from "../components/Login";
import Register from "../components/Register";
import Profile from "../components/Profile";
import ResetPassword from "../components/ResetPassword";
import ForgotPassword from "../components/ForgotPassword";
import ProtectedRoute from "./ProtectedRoute";
import NewModel from "../components/model/NewModel";
import Model from "../components/Model";
import About from "../components/About";
import Terms from "../components/Terms";
import Privacy from "../components/Privacy";
import PrivacyPractices from "../components/PrivacyPractices";
import Contact from "../components/Contact";
import Cart from "../components/Cart";
import Settings from "../components/Settings";
import Orders from "../components/Orders";
import Purchases from "../components/Purchases";
import Store from "../components/Store";
import Webhooks from "../components/Webhooks";
import MyModels from "../components/MyModels";
import HowItWorks from "../components/howitworks/HowItWorks";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/profile"
        element={<ProtectedRoute element={<Profile />} />}
      />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/model/new" element={<NewModel />} />
      <Route path="/models/me" element={<MyModels />} />
      <Route path="/model/u/:uuid" element={<Model />} />
      <Route path="/model/u/:uuid/edit" element={<Model edit={true} />} />
      <Route path="/about" element={<About />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/privacy-practices" element={<PrivacyPractices />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/purchases" element={<Purchases />} />
      <Route path="/store" element={<Store />} />
      <Route path="/webhooks" element={<Webhooks />} />
      <Route path="/how-it-works" element={<HowItWorks />} />
    </Routes>
  );
}

export default AppRoutes;
