import React from "react";
import AppRoutes from "./routes/Routes";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/carousel/styles.css";

import { createTheme, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

const theme = createTheme({
  fontFamily: "Open Sans, sans-serif",
});

function App() {
  return (
    <MantineProvider theme={theme}>
      <Notifications />
      <AppRoutes />
    </MantineProvider>
  );
}

export default App;
