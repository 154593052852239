import { Loader, Select } from "@mantine/core";

import { useGetLicenseTypes } from "../../hooks/license_type";

export default function LicenseTypeSelect({ ...props }) {
  const {
    data: license_types,
    error,
    isError,
    isPending,
  } = useGetLicenseTypes();

  // sometimes value is updated before data is fetched. this seems to
  // prevent it from not showing the value in those cases
  if (isPending) {
    return <Loader />;
  }

  return (
    <Select
      label="License"
      placeholder="Select a license"
      disabled={isPending}
      data={
        license_types && license_types.map((license_type) => license_type.value)
      }
      error={isError ? error.message : null}
      {...props}
    />
  );
}
