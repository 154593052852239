import React from "react";
import { Text } from "@mantine/core";
import { Container } from "@mantine/core";

import Layout from "./Layout";

function Contact() {
  return (
    <Layout>
      <Container>
        <h1>Contact</h1>
        <Text></Text>
        <div style={{ height: "50vh" }}></div>
      </Container>
    </Layout>
  );
}

export default Contact;
