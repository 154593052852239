import React from "react";
import { Container } from "@mantine/core";
import Markdown from "react-markdown";

import terms from "../../markdown/terms.md?raw";
import Layout from "./Layout";

function Terms() {
  return (
    <Layout>
      <Container>
        <Markdown>{terms}</Markdown>
        <div style={{ height: "50vh" }}></div>
      </Container>
    </Layout>
  );
}

export default Terms;
