import React from "react";

import { useForm } from "@mantine/form";
import { Button, Group, TextInput } from "@mantine/core";
import { notifications } from "@mantine/notifications";

import Layout from "./Layout";

import { useForgotPassword } from "../hooks/forgot_password";

function ForgotPassword() {
  const form = useForm({
    initialValues: {
      email: "",
    },
  });

  const forgotPasswordMutation = useForgotPassword();

  const handleSubmit = (values) => {
    forgotPasswordMutation.mutate(
      { email: values.email },
      {
        onSuccess: () => {
          notifications.showNotification({
            title: "Password reset email sent",
            message: "Password reset email sent",
            color: "blue",
          });
        },
      },
    );
  };

  return (
    <Layout>
      <div className="container">
        <h2>Forgot Password</h2>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Email"
            placeholder="your@email.com"
            key={form.key("email")}
            {...form.getInputProps("email")}
          />
          <Group justify="flex-end" mt="md">
            <Button
              type="submit"
              variant="outline"
              color="blue"
              loading={forgotPasswordMutation.isPending}
            >
              Send Email
            </Button>
          </Group>
        </form>
      </div>
    </Layout>
  );
}

export default ForgotPassword;
