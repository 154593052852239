import React from "react";
import { Link } from "react-router-dom";
import { AppShell, Badge, Burger, Flex, Group, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconHexagon3d } from "@tabler/icons-react";

import Navbar from "./Navbar";
import LoginWidget from "./LoginWidget";
import Feedback from "./Feedback";
import { MantineLink } from "./mantine";

function Layout({ children }) {
  const [opened, { toggle }] = useDisclosure();
  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 300,
        breakpoint: "sm",
        collapsed: { desktop: true, mobile: !opened },
      }}
      padding="md"
    >
      <Feedback />
      <AppShell.Header>
        <Group h="100%" px="md">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <Group justify="space-between" style={{ flex: 1 }} align="center">
            <MantineLink to="/" style={{ textDecoration: "none" }}>
              <Flex align="center" justify="center">
                <IconHexagon3d />{" "}
                <Text component="span" align="top">
                  3DMarket
                </Text>
                <Badge size="sm" color="yellow" variant="filled" mb="md">
                  Beta
                </Badge>
              </Flex>
            </MantineLink>
            <Group ml="xl" gap={0} visibleFrom="sm">
              <Navbar />
              <LoginWidget />
            </Group>
          </Group>
        </Group>
      </AppShell.Header>
      <AppShell.Main mb="lg">{children}</AppShell.Main>
      <AppShell.Footer>
        <Flex justify="center" align="center">
          <MantineLink to="/about" mx="md">
            About
          </MantineLink>
          <MantineLink to="/terms" mx="md">
            Terms of Use
          </MantineLink>
          <MantineLink to="/privacy" mx="md">
            Privacy
          </MantineLink>
          <MantineLink to="/contact" mx="md">
            Contact
          </MantineLink>
        </Flex>
        <Flex justify="flex-end" align="center"></Flex>
      </AppShell.Footer>
    </AppShell>
  );
}

export default Layout;
