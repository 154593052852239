import { useMutation } from "@tanstack/react-query";

import axios from "../api/axios";

export const usePostFeedback = () => {
  return useMutation({
    mutationFn: async ({ feedback, url, location }) => {
      return axios.post("/feedback", { feedback, url, location });
    },
  });
};
