import React from "react";

import { Button, Table } from "@mantine/core";

import LayoutSettings from "./LayoutSettings";

function Orders() {
  return (
    <LayoutSettings activeTab="orders">
      <h3>Orders</h3>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Order ID</Table.Th>
            <Table.Th>Product</Table.Th>
            <Table.Th>Price</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td>1</Table.Td>
            <Table.Td>Product 1</Table.Td>
            <Table.Td>$10.00</Table.Td>
            <Table.Td>Completed</Table.Td>
            <Table.Td>
              <Button size="xs" variant="light">
                View
              </Button>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>2</Table.Td>
            <Table.Td>Product 2</Table.Td>
            <Table.Td>$20.00</Table.Td>
            <Table.Td>Completed</Table.Td>
            <Table.Td>
              <Button size="xs" variant="light">
                View
              </Button>
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </LayoutSettings>
  );
}

export default Orders;
