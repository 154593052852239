import React from "react";

import { useNavigate } from "react-router-dom";
import { Card, Container, Image, Text, Grid } from "@mantine/core";
import { Loader } from "@mantine/core";

import { useGetMyPaginatedModels } from "../hooks/model";
import Layout from "./Layout";

const MyModelItem = (model) => {
  const navigate = useNavigate();
  const handleClick = (uuid) => {
    navigate(`/model/u/${uuid}`);
  };

  return (
    <Card
      shadow="sm"
      padding="md"
      radius="md"
      withBorder
      style={{ cursor: "pointer" }}
      onClick={() => handleClick(model.uuid)}
    >
      <Card.Section>
        <Image
          src={new URL(model.image_url, import.meta.env.VITE_BASE_URL)}
          h={220}
        />
      </Card.Section>
      <div style={{ marginTop: "1rem" }}>
        <Text
          align="center"
          size="lg"
          weight={700}
          truncate="end"
          title={model.name}
        >
          {model.name}
        </Text>
      </div>
    </Card>
  );
};

const MyModels = () => {
  const { data, isPending } = useGetMyPaginatedModels();

  if (isPending) {
    return <Loader />;
  }

  if (!data) {
    return <div>No models found</div>;
  }

  return (
    <Layout>
      <Container>
        <Text
          align="center"
          size="xl"
          weight={700}
          style={{ marginBottom: 20 }}
        >
          My Models
        </Text>
        <Grid>
          {data.map((model) => (
            <Grid.Col span={4} key={model.uuid}>
              <MyModelItem {...model} />
            </Grid.Col>
          ))}
        </Grid>
      </Container>
    </Layout>
  );
};

export default MyModels;
