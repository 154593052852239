import React from "react";
import { Container } from "@mantine/core";

import Layout from "../Layout";
import Create from "./create";

function NewModel() {
  return (
    <Layout>
      <Container>
        <Create />
      </Container>
    </Layout>
  );
}

export default NewModel;
