import React from "react";

import { Button, Container, Grid, Title, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { Link } from "react-router-dom";
import classes from "./HeroBullets.module.css";

export default function HeroBullets() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <Container mb="lg">
      <Title className={classes.title}>Shape the Future of 3D Printing</Title>

      <Text size="xl" className={classes.title}>
        Designers and Printers, Join 3DMarket.app Today
      </Text>

      <Container p={0}>
        <Text size="lg" c="dimmed" align="center">
          Be among the first to experience a new platform{" "}
          <Text component="span" color="blue" weight={700}>
            connecting 3D designers with printers.
          </Text>{" "}
          Collaborate, showcase your work, and grow your business before our
          official launch.
        </Text>
      </Container>
      <Container>
        <Grid
          mt="md"
          justify="center"
          align="center"
          style={{ textAlign: "center" }}
        >
          <Grid.Col span={{ xs: 12, sm: 5, md: 4, lg: 3 }}>
            <Button
              className={classes.control}
              component={Link}
              to="/how-it-works"
              size="lg"
              variant="outline"
              color="blue"
              fullWidth={isMobile}
            >
              How It Works
            </Button>
          </Grid.Col>
          <Grid.Col span={{ sm: 12, md: 5, lg: 3 }}>
            <Button
              className={classes.control}
              component={Link}
              to="/register"
              size="lg"
              variant="filled"
              color="green"
              fullWidth={isMobile}
            >
              Join the beta
            </Button>
          </Grid.Col>
        </Grid>
      </Container>
    </Container>
  );
}
