import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Fieldset, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { zxcvbn } from "@zxcvbn-ts/core";
import { notifications } from "@mantine/notifications";

import { useRegisterUser } from "../hooks/register";
import Layout from "./Layout";

function Register() {
  const navigate = useNavigate();

  const registerUserMutation = useRegisterUser();

  const handleSubmit = (values) => {
    registerUserMutation.mutate(
      {
        email: values.email,
        username: values.username,
        password: values.password,
        timezone: values.timezone,
      },
      {
        onSuccess: () => {
          notifications.show({
            message:
              "Registration successful! Please login using your email and password.",
            color: "green",
          });
          navigate("/login");
        },
        onError: (error) => {
          notifications.show({
            message: "Registration failed: " + error.message,
            color: "red",
          });
        },
      },
    );
  };

  const form = useForm({
    initialValues: {
      email: "",
      username: "",
      password: "",
      timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone || "America/New_York",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      username: (value) => (value.length > 0 ? null : "Username is required"),
      password: (value) => {
        const result = zxcvbn(value);
        if (result.score < 2) {
          return "Password is not strong enough";
        }
      },
    },
  });

  return (
    <Layout>
      <Container>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Fieldset legend="Create an Account">
            <TextInput
              label="Username"
              placeholder="Username"
              key={form.key("username")}
              {...form.getInputProps("username")}
            />
            <TextInput
              label="Email"
              placeholder="Email"
              key={form.key("email")}
              {...form.getInputProps("email")}
            />
            <TextInput
              type="password"
              label="Password"
              placeholder="Password"
              key={form.key("password")}
              {...form.getInputProps("password")}
            />
            <Group justify="flex-end" mt="md">
              <Button
                type="submit"
                variant="outline"
                color="blue"
                loading={registerUserMutation.isPending}
              >
                Register
              </Button>
            </Group>
          </Fieldset>
        </form>
      </Container>
    </Layout>
  );
}

export default Register;
