import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../api/axios";

export const useGetCurrentUser = () => {
  return useQuery({
    queryKey: ['currentUser'],

    queryFn: async () => {
      try {
        const response = await axios.get("/users/me");
        return response.data;
      } catch (error) {
        if (error?.response?.status === 401) {
          return null;
        }
        throw error;
      }
    }
  });
};

export const usePatchCurrentUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (values) => {
      return axios.patch("/users/me", values);
    }
  });
};
