import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../api/axios";

export const useGetCart = () => {
  return useQuery({
    queryKey: ["cart"],
    queryFn: async () => {
      return await axios.get("/cart").then((res) => res.data);
    },
  });
};

export const useAddToCart = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ model_uuid, model_file_uuid }) => {
      return await axios.post("/cart/add", { model_uuid, model_file_uuid });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("cart");
    },
  });
};

export const useRemoveFromCart = () => {
  return useMutation({
    mutationFn: async ({ cart_item_uuid }) => {
      return await axios.post("/cart/remove", { cart_item_uuid });
    },
  });
};
