import { Anchor } from "@mantine/core";
import { Link } from "react-router-dom";

export function MantineLink({ to, children, ...props }) {
  return (
    <Anchor component={Link} to={to} {...props}>
      {children}
    </Anchor>
  );
}
