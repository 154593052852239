import React from "react";
import { Affix, Dialog, Group, Button, Textarea } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import { usePostFeedback } from "../hooks/feedback";

export default function Feedback() {
  const [opened, { close, open }] = useDisclosure();

  const form = useForm({
    initialValues: {
      feedback: "",
    },
  });

  const location = useLocation();
  const { mutate, isPending } = usePostFeedback();

  const handleSubmit = (values) => {
    mutate(
      {
        feedback: values.feedback,
        url: window.location.href,
        location: location.pathname,
      },
      {
        onSuccess: () => {
          notifications.show({
            title: "Feedback sent",
            message: "Thank you for your feedback!",
            color: "green",
          });
          close();
        },
      },
    );
  };

  return (
    <>
      {!opened && (
        <Affix position={{ bottom: 30, right: 20 }}>
          <Button
            rightSection={<IconChevronRight size="1rem" />}
            onClick={open}
          >
            Feedback
          </Button>
        </Affix>
      )}
      <Dialog
        size="xl"
        withBorder
        withCloseButton
        opened={opened}
        onClose={close}
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Textarea
            label="Feedback"
            placeholder="How can we improve this page?"
            key={form.key("feedback")}
            autosize
            minRows={5}
            maxRows={15}
            resize="both"
            {...form.getInputProps("feedback")}
          />
          <Group direction="column" spacing="md" pt="md">
            <Button
              type="submit"
              variant="outline"
              color="blue"
              loading={isPending}
            >
              Send Feedback
            </Button>
          </Group>
        </form>
      </Dialog>
    </>
  );
}
