import React from "react";

import { Button, Table, Text } from "@mantine/core";
import { IconPrinter } from "@tabler/icons-react";

import LayoutSelling from "./LayoutSelling";

function Purchases() {
  return (
    <LayoutSelling activeTab="purchases">
      <h3>Purchases</h3>
      <Table striped>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Order ID</Table.Th>
            <Table.Th>Product</Table.Th>
            <Table.Th>Price</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Payment</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td>1</Table.Td>
            <Table.Td>Product 1</Table.Td>
            <Table.Td>$10.00</Table.Td>
            <Table.Td>
              <Text color="red">Unfulfilled</Text>
            </Table.Td>
            <Table.Td>
              <Text>Not Paid</Text>
            </Table.Td>
            <Table.Td>
              <Button size="xs" color="blue" rightSection={<IconPrinter />}>
                Print Shipping Label
              </Button>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>2</Table.Td>
            <Table.Td>Product 2</Table.Td>
            <Table.Td>$20.00</Table.Td>
            <Table.Td>
              <Text>Delivered</Text>
            </Table.Td>
            <Table.Td>
              <Text>Paid</Text>
            </Table.Td>
            <Table.Td>
              <Button size="xs" variant="light">
                View
              </Button>
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </LayoutSelling>
  );
}

export default Purchases;
